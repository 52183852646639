/* ===============================
Header css
===================================*/

.site__header {
	/* height: 58px; */
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	color: #212529;
}

.top__bar {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-color: #fafafa;
	border-bottom: 1px solid #f0f0f2;
	width: 100%;
}

.top__bar a {
	color: inherit;
}

.nav__area {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	height: 58px;
}

.inner {
	margin: 0 auto;
	padding: 0 20px;
	max-width: 900px;
}

.nav__area__inner {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	/* height: 58px; */
	margin: 0 auto;
	padding: 0 20px;
	max-width: 900px;
}

.nav__area__logo {
	text-decoration-line: none;
}

.nav__logo {
	margin: 0 3px;
	height: 40px;
	position: absolute;
	top: 3px;
}

.nav__logo_mobile {
	margin: 0 3px;
	height: 30px;
	position: absolute;
	top: 7.5px;
}

.menus {
	display: flex;
	list-style: none;
}

.menu__items,
.menu__items_active {
	position: relative;
	font-size: 14px;
	font-weight: 600;
}

.menu__items a,
.menu__items_active a {
	display: block;
	font-size: 14px;
	color: inherit;
	text-decoration: none;
	padding: 0.7rem 1rem;
	margin-bottom: 0;
	-webkit-transition: color 200ms ease-out;
	-moz-transition: color 200ms ease-out;
	-o-transition: color 200ms ease-out;
	transition: color 200ms ease-out;
}

.menu__items_active a {
	color: #a80010;
	-webkit-transition: color 200ms ease-in;
	-moz-transition: color 200ms ease-in;
	-o-transition: color 200ms ease-in;
	transition: color 200ms ease-in;
}

.menu__items:last-child a {
	padding-right: 15px;
}

.menu__items a:hover {
	background-color: #f2f2f2;
}

@media screen and (max-width: 500px) {
	.menu__items a,
	.menu__items_active a {
		padding: 0.7rem;
	}
	.nav__area__inner {
		flex-direction: column;
		padding: 10px 0 5px 0;
	}
}

@media (prefers-color-scheme: dark) {
	.site__header {
		/* height: 58px; */
		box-shadow: none;
		color: whitesmoke;
		border-bottom: 1px solid #444;
	}

	.menu__items a:hover {
		background-color: #444444;
	}
}
