.DropZone {
	border: 3px dashed #888;
	border-radius: 4px;
	color: #aaa;
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	padding: 2em;
	cursor: pointer;
}

.DropZone:hover {
	background-color: rgba(0, 0, 0, 0.025);
}

.DropZone:focus,
.DropZone--over-document {
	border: 3px dashed #599eff;
}

.DropZone--over {
	border: 3px dashed #259925;
}

/* body { */
/* margin: 0; */
/* padding: 0; */
/* font-weight: bold; */
/* -webkit-font-smoothing: antialiased; */
/* -moz-osx-font-smoothing: grayscale; */
/* } */

#_1pQCo {
	border-radius: 100%;
	background-color: #999;
	position: relative;
	border: 1px solid #999;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin: 0 auto;
}

#_1pQCo input {
	display: none !important;
}

#_1pQCo aside {
	position: absolute;
	border: 5px solid #d4d2d2;
	border-top-color: #3498db;
	border-radius: 50%;
	width: 110%;
	height: 110%;
	-webkit-animation: _1sp4O 0.6s linear infinite;
	animation: _1sp4O 0.6s linear infinite;
}

#_1pQCo button {
	border: none;
	border-radius: 100%;
	padding: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 0;
	right: 5px;
	cursor: pointer;
	height: 25%;
	width: 25%;
}

#_1pQCo button:focus,
#_1pQCo button:hover {
	outline: none;
}

#_1pQCo img {
	height: 100%;
	width: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	-o-object-position: top;
	object-position: top;
	border-radius: 100%;
}

#_2Cu_5 {
	position: fixed;
	background-color: rgba(0, 0, 0, 0.87);
	min-height: 100vh;
	max-height: 100vh;
	width: 100%;
	z-index: 1e51;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	-ms-overflow-style: none;
}

#_2Cu_5 * {
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif !important;
	font-weight: bolder !important;
}

#_2Cu_5 section {
	max-width: 600px;
	width: 100%;
	padding: 0 2%;
	border-radius: 4px;
	display: flex;
	max-height: calc(100% - 64px);
	flex-direction: column;
	position: relative;
	overflow-y: auto;
	color: rgba(0, 0, 0, 0.87);
	transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

#_2Cu_5 section h1 {
	text-align: left;
	flex: 0 0 auto;
}

#_2Cu_5 section article {
	flex: 1 1 auto;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

#_2Cu_5 section article aside {
	border: 5px solid #f3f3f3;
	border-top-color: #3498db;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	-webkit-animation: _1sp4O 0.5s linear infinite;
	animation: _1sp4O 0.5s linear infinite;
	margin: 2%;
}

#_2Cu_5 section div {
	flex: 0 0 auto;
	display: flex;
	padding: 10px;
	align-items: center;
	justify-content: space-between;
}

#_2Cu_5 section div button {
	background-color: rgba(25, 118, 210, 0.04);
	padding: 6px 15px;
	font-size: 0.875rem;
	min-width: 64px;
	box-sizing: border-box;
	font-weight: 500;
	line-height: 1.75;
	border-radius: 4px;
	letter-spacing: 0.02857em;
	text-transform: uppercase;
	border: 0;
	cursor: pointer;
	margin: 0;
	display: inline-flex;
	outline: 0;
	position: relative;
	align-items: center;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border-radius: 0;
	vertical-align: middle;
	justify-content: center;
	text-decoration: none;
	background-color: transparent;
	-webkit-tap-highlight-color: transparent;
}

@media screen and (max-width: 500px) {
	#_2Cu_5 section {
		width: 100%;
		height: 100vh;
		max-width: 100%;
		max-height: none;
		border-radius: 0;
		padding: 0;
	}
	#_2Cu_5 section h1 {
		background-color: #f1f1f1;
		border-bottom: 1px solid #ccc;
		margin: 0;
		padding: 3%;
	}
	#_2Cu_5 section article {
		background-color: transparent !important;
		flex: none;
		position: fixed;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
	}
	#_2Cu_5 section article canvas {
		height: 100%;
		width: 100%;
	}
	#_2Cu_5 section div {
		position: fixed;
		bottom: 0;
		width: 100%;
	}
}

@-webkit-keyframes _1sp4O {
	0% {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(1turn);
	}
}

@keyframes _1sp4O {
	0% {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(1turn);
	}
}
