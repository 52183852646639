.home {
	text-align: center;
}
.home p {
	font-size: 1rem;
	line-height: 1.75rem;
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
	text-align: left;
}

.home .logoContainer {
	display: flex;
	justify-content: center;
}

.home .logo {
	width: 135px;
	margin-top: 20px;
}
