.admin {
	position: relative;
}

.admin_buttons {
	display: grid;
	grid-template-columns: repeat(6, 16.6%);
	margin-bottom: 20px;
}

.admin_buttons button {
	font-size: 17px;
	padding: 16px 25px;
}

.admin .logout {
	position: absolute;
	top: 0;
	right: 0;
	transform: scale(75%);
}

.admin .edit_products {
	height: 100%;
}

.admin .default {
	font-size: 25px;
	text-align: center;
}

@media (max-width: 768px) {
	.admin_buttons {
		display: flex;
		flex-direction: column;
	}
}
