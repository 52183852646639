.layout_container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	justify-content: space-between;
}

.inner__wrapper {
	flex-grow: 1;
}

.content {
	margin: 0 auto;
	max-width: 750px;
	padding: 2.5rem 1rem;
}
