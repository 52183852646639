.inner {
	margin: 0 auto;
	padding: 8px 20px 10px 20px;
	/* max-width: 900px; */
	border-top: 1px solid #e8e8e8;
	width: 100%;
}

.footer__content {
	font-size: 14px;
	color: #6a6767;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid rgba(232, 232, 232, 0.5);
	padding-top: 20px;
	padding-bottom: 20px;
}

.footer_gear {
	transform: translateY(2px);
}

.footer_link {
	display: inline;
	text-decoration: underline;
}

.footer_rss,
.footer_json,
.footer_atom {
	cursor: pointer;
}

.footer_rss,
.footer_json {
	margin-right: 10px;
}

.hours {
	text-align: right;
	margin: 22px auto 35px auto;
	/* font-size: 1.5rem; */
}

@media screen and (max-width: 500px) {
}

@media (prefers-color-scheme: dark) {
	.footer__content,
	.footer_link {
		color: darkgray;
	}

	.footer__content {
		border-top: 1px solid rgb(68, 68, 68, 0.5);
	}
	.inner {
		border-top: 1px solid #444444;
	}
}
